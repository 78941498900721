<template>
  <center-screen>
    <p v-if="!code">
      A link has been sent to your email ({{ email }}) to update your passwod.
    </p>

    <template v-if="complete" v-slot:actions>
      <v-spacer />
      <v-btn color="primary" tile depressed @click="navigateToLogin()">
        Back to Login
      </v-btn>
    </template>
  </center-screen>
</template>

<script>
import { mapActions } from "vuex";
import CenterScreen from '../../common/layouts/CenterScreen.vue';

export default {
  name: "PasswordReset",
  components: { CenterScreen },
  data() {
    return {
      redirect: "/",
      email: null,
    };
  },
  mounted() {
    const { email } = this.$route.params;
    this.email = email;
  },
  methods: {
    ...mapActions("auth", ["login"]),

    navigateToLogin() {
      this.$$router.push({ name: "login" });
    },
  },
};
</script>
